export var UMSoundResult;
(function (UMSoundResult) {
    /**
     Operation OK. Success case! Nothing to worry about!
    */
    UMSoundResult[UMSoundResult["OK"] = 1] = "OK";
    /**
    General error: The operation failed. For detailed information enable trace output.
    */
    UMSoundResult[UMSoundResult["UNSPECIFIED_ERROR"] = 0] = "UNSPECIFIED_ERROR";
    /**
    General error: No memory allocated.
    */
    UMSoundResult[UMSoundResult["NO_INIT"] = -1] = "NO_INIT";
    /**
    General error: No such player
    */
    UMSoundResult[UMSoundResult["NO_PLAYER"] = -2] = "NO_PLAYER";
    /**
    General error: there is an issue with the monitor ( this means the playback to headphones for example)
    */
    UMSoundResult[UMSoundResult["NO_MONITOR"] = -3] = "NO_MONITOR";
    /**
    General error: file name malformed
    */
    UMSoundResult[UMSoundResult["WRONG_FILENAME"] = -4] = "WRONG_FILENAME";
    /**
       General error: The passed parameter is invalid.
        The most frequent case for this error is, when using the structures with prefix EDAPIX_. The member cbSize have to filled before using it (Only for C/C++ programmers).
        Second case: The byte order does not agree. Please check your compiler options which affect the byte order of structure.
    */
    UMSoundResult[UMSoundResult["FAIL_INVALID_PARAM"] = -5] = "FAIL_INVALID_PARAM";
    /**
       Should never occur probably a error that comes from bad software design
    */
    UMSoundResult[UMSoundResult["INTERNAL_ERROR"] = -6] = "INTERNAL_ERROR";
    /**
  
    */
    UMSoundResult[UMSoundResult["RECORDER"] = -7] = "RECORDER";
    /**
       this channel is not available!
    */
    UMSoundResult[UMSoundResult["NO_CHANNEL_AVAILABLE"] = -8] = "NO_CHANNEL_AVAILABLE";
    /**
       this license is not valid for the particular software
    */
    UMSoundResult[UMSoundResult["WRONG_LICENSE"] = -9] = "WRONG_LICENSE";
    /**
       hacker attack!
    */
    UMSoundResult[UMSoundResult["HACKER_ATTACK"] = -10] = "HACKER_ATTACK";
    /**
       purchaseID is invalid!
    */
    UMSoundResult[UMSoundResult["PURCHASE_ID_INVALID"] = -11] = "PURCHASE_ID_INVALID";
    /**
       EQ object is not valid
    */
    UMSoundResult[UMSoundResult["NO_VALID_EQ_OBJECT"] = -12] = "NO_VALID_EQ_OBJECT";
    /**
     */
    UMSoundResult[UMSoundResult["NOT_ENOUGH_MEMORY"] = -13] = "NOT_ENOUGH_MEMORY";
    /**
       you need to make sure the FMOD version is valid Ultramixer
    */
    UMSoundResult[UMSoundResult["WRONG_FMOD_VERSION"] = -14] = "WRONG_FMOD_VERSION";
    /**
       file format is not supported!
    */
    UMSoundResult[UMSoundResult["UNSUPPORTED_FILE_FORMAT"] = -15] = "UNSUPPORTED_FILE_FORMAT";
    /**
     */
    UMSoundResult[UMSoundResult["TIME_STRETCH_PLUGIN_NOT_FOUND"] = -16] = "TIME_STRETCH_PLUGIN_NOT_FOUND";
    /**
    did not find an CD on your system
    */
    UMSoundResult[UMSoundResult["NO_CD_FOUND"] = -17] = "NO_CD_FOUND";
    /**
    the buffer size is not correct
    */
    UMSoundResult[UMSoundResult["WRONG_BUFFER_SIZES"] = -18] = "WRONG_BUFFER_SIZES";
    /**
    the beats per minute are set to an invalid value
    */
    UMSoundResult[UMSoundResult["BPM_INVALID"] = -19] = "BPM_INVALID";
    /**
    * there are not enough slots on the sampler available
    */
    UMSoundResult[UMSoundResult["NOT_ENOUGH_SAMPLER_SLOTS"] = -20] = "NOT_ENOUGH_SAMPLER_SLOTS";
    /**
    this sample slot is not available
    */
    UMSoundResult[UMSoundResult["NO_SUCH_SAMPLE_SLOT"] = -21] = "NO_SUCH_SAMPLE_SLOT";
    /**
       Currently not implemented. Maybe internally forgotten because the methods outside are always virtual and not implemented.
    */
    UMSoundResult[UMSoundResult["NOT_IMPLEMENTED"] = -22] = "NOT_IMPLEMENTED";
    /**
    detected a null pointer from the outside!
    */
    UMSoundResult[UMSoundResult["NULL_POINTER"] = -23] = "NULL_POINTER";
    /**
       probably the file is already opened by an application??
    */
    UMSoundResult[UMSoundResult["CANT_OPEN_FILE"] = -24] = "CANT_OPEN_FILE";
    /**
       the specified system is not available!
    */
    UMSoundResult[UMSoundResult["NO_SUCH_SYSTEM"] = -25] = "NO_SUCH_SYSTEM";
    /**
       Windows only! Hardware Acceleration is disabled!
            --> Warn the user!
    */
    UMSoundResult[UMSoundResult["ACCELERATION_DISABLED"] = -26] = "ACCELERATION_DISABLED";
    /**
       Quicktime is not installed
    */
    UMSoundResult[UMSoundResult["QUICKTIME_MISSING"] = -27] = "QUICKTIME_MISSING";
    /**
       wrong Quicktime version detected
            --> warn the user to upgrade!
    */
    UMSoundResult[UMSoundResult["WRONG_QUICKTIME"] = -28] = "WRONG_QUICKTIME";
    /**
    you need to specify an image buffer to use quicktime!
    */
    UMSoundResult[UMSoundResult["QUICKTIME_IMAGE_BUFFER_MISSING"] = -29] = "QUICKTIME_IMAGE_BUFFER_MISSING";
    /**
    the specified image buffer is not sufficient for the video
    */
    UMSoundResult[UMSoundResult["QUICKTIME_IMAGE_BUFFER_SIZE"] = -30] = "QUICKTIME_IMAGE_BUFFER_SIZE";
    /**
       wave access can be deactivated in terms of performance
    */
    UMSoundResult[UMSoundResult["WAVE_ACCESS_DEACTIVATED"] = -31] = "WAVE_ACCESS_DEACTIVATED";
    /**
       older license detected
        --> warn user to upgrade it's license!
    */
    UMSoundResult[UMSoundResult["VERSION_NOT_VALID_OLD_LICENSE"] = -32] = "VERSION_NOT_VALID_OLD_LICENSE";
    /**
       the specified file was not opened!
        use open() to open the file.
    */
    UMSoundResult[UMSoundResult["FILE_NOT_OPEN"] = -33] = "FILE_NOT_OPEN";
    /**
       General error: The operation is not supported.
    */
    UMSoundResult[UMSoundResult["NOT_SUPPORTED"] = -34] = "NOT_SUPPORTED";
    /**
    this is not a new image... not worth to draw it again!
    */
    UMSoundResult[UMSoundResult["NO_NEW_IMAGE"] = -35] = "NO_NEW_IMAGE";
    /**
       * @brief the volume has been set to an invalid value
       *
       */
    UMSoundResult[UMSoundResult["INVALID_VOLUME_LEVEL"] = -36] = "INVALID_VOLUME_LEVEL";
    /**
    * @brief the sound library could not be loaded may be it is not in the correct path
    *
    */
    UMSoundResult[UMSoundResult["MISSING_SOUND_LIBRARY"] = -37] = "MISSING_SOUND_LIBRARY";
    /**
    * @brief usage of an invalid handle appeared at the sound library
    *
    */
    UMSoundResult[UMSoundResult["INVALID_OBJECT_HANDLE"] = -38] = "INVALID_OBJECT_HANDLE";
    /**
    * a File could not be found
    *
    */
    UMSoundResult[UMSoundResult["FILE_NOT_FOUND"] = -39] = "FILE_NOT_FOUND";
    /**
    * @brief somehow a DSP could not be created.
    *
    */
    UMSoundResult[UMSoundResult["DSP_CREATION_FAULT"] = -40] = "DSP_CREATION_FAULT";
    /**
    * @brief the selected sound card does not exist in the System anymore
    *
    */
    UMSoundResult[UMSoundResult["SOUND_CARD_NOT_FOUND"] = -41] = "SOUND_CARD_NOT_FOUND";
    /**
    * @brief a parameter for the flanger effect has been set to an invalid value
    *
    */
    UMSoundResult[UMSoundResult["INVALID_FLANGER_PARAMETER"] = -42] = "INVALID_FLANGER_PARAMETER";
    /**
    * @brief the sound system could not set the loop point properly
    *
    */
    UMSoundResult[UMSoundResult["LOOP_POINTS_NOT_SET"] = -43] = "LOOP_POINTS_NOT_SET";
})(UMSoundResult || (UMSoundResult = {}));
export function numberToUMSoundResult(value) {
    const keys = Object.keys(UMSoundResult).filter((key) => isNaN(Number(key)));
    for (const key of keys) {
        if (UMSoundResult[key] === value) {
            return UMSoundResult[key];
        }
    }
    return UMSoundResult.UNSPECIFIED_ERROR;
}
